import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout'
import TabDescription from '../components/TabDescription'
import SEO from '../components/SEO'
import AllArticles from '../components/AllArticles'

export const query = graphql`
  {
    contentfulTabDescription(tabname: { eq: "Articles" }) {
      description {
        childMarkdownRemark {
          html
        }
      }
      tabname
      title
    }
  }
`
const Blog = () => {
  const data = useStaticQuery(query)
  const mytab = data.contentfulTabDescription

  return (
    <Layout>
      <SEO
        title='Blog'
        description='Blijf in contact voor tips en updates 🤍'
      />
      <main className='page'>
        <TabDescription {...mytab}></TabDescription>
        <AllArticles />
      </main>
    </Layout>
  )
}

export default Blog
